<script setup>
import { ref } from 'vue'
const clusterUrl = process.env.VUE_APP_CLUSTER_URL
let wallet_address = ref('');
function getFaucet() {
    console.log(wallet_address)
    if (wallet_address.value){
        fetch(clusterUrl, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({"jsonrpc": "2.0","id": 1,"method": "requestAirdrop","params": [wallet_address.value, 100000000]})
        })
        .then((response) => {
            response.json().then((data) => {
            wallet_address.value = '';
            console.log(data)
            });
        })
        .catch((err) => {
            console.error(err);
        });
}
}

</script>

<template>
    <div id="app" class="flex justify-center items-center h-screen">
        <div class="bg-purple p-4 rounded-lg shadow-md flex">
            <input v-model="wallet_address" type="text" placeholder="Enter AX address..." class="px-4 py-2 border border-gray-300 focus:outline-none focus:border-blue-500 rounded-l-md flex-grow">
            <button @click="getFaucet" class="bg-purple-500 hover:bg-purple-300 text-white px-4 py-2 rounded-r-md">
                Request Token
            </button>
        </div>
    </div>
</template>
