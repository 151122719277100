<script>
const clusterUrl = process.env.VUE_APP_CLUSTER_URL
import { useWorkspace } from '@/composables'

export default {
  props: {
    msg: String,
  },
  data() {
    return {
      balance: 'Click to Update',
    };
  },
  methods: {
    getBalance(){
      const { wallet } = useWorkspace()
      console.log(wallet);
      if (wallet.value?.publicKey){
            fetch(clusterUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({"jsonrpc": "2.0","id": 1,"method": "getBalance","params": [wallet.value.publicKey]})
          })
            .then((response) => {
              response.json().then((data) => {
                this.balance = Math.floor(data.result.value/100000000);
              });
            })
            .catch((err) => {
              console.error(err);
              this.balance = 0;
            });

    }
  },
    mounted() {
      this.getBalance()
      }
    }
};
</script>


<template>
  <div class="balance">
    <button class="text-white px-4 py-2 rounded-full font-semibold bg-purple-500" @click="getBalance">{{ balance }} AX</button>
  </div>
</template>

<style>
</style>
